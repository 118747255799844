<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10319')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__wrap">
							<div class="frms">
								<!-- 아이디 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10129')}}</h3>
									<div class="frms__wrap">
										<input type="email" class="ipt-frm" :value="userData.mem_id" readonly disabled>
									</div>
								</div>
								<!-- 연락처 변경 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10320')}}</h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" :value="checkPhoneNumber(userPhoneNumber)[0]" readonly :placeholder="t('10778')"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn btn-medium btn-secondary active" v-show="!isEditPhone" @click="onClickEditPhone">{{t('10321')}}</button>
										</div>
									</div>
									<!-- 인증하기 -->
									<div class="frms__wrap" v-show="isEditPhone">
										<input type="tel" class="ipt-frm" v-model="phoneNumber" @keyup="checkNumber" maxlength="13" :placeholder="t('10778')"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn btn-medium btn-secondary active" @click="onClickSendPhoneNumber(phoneNumber)">인증하기</button><!-- 재번역 체크 -->
										</div>
									</div>
									<!-- 재전송 -->
									<div class="frms__wrap" v-show="isEditPhone">
										<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="userPhoneValidateNumber" @input="event => userPhoneValidateNumber = event.target.value"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<!-- 남은 시간 -->
											<span class="frms__time eng">
											</span>
											<button type="button" class="btn btn-medium btn-secondary active" @click="checkValidateNumber">{{t('10143')}}</button>
										</div>
									</div>
                    <div class="frms__wrap" v-show="phoneValidateTime < 180 && isEditPhone">
	                    <button type="button" class="btn-resend" @click="reSend">
		                    {{t('10125')}}
		                    <span class="frms__time eng">
											 		{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
												</span>
	                    </button>
										</div>
								</div>
							
								<!-- 닉네임 변경 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10322')}}</h3>
									<div class="frms__wrap">
										<input type="text" class="ipt-frm" v-model="nickname" :placeholder="t('10830')"  maxlength="6"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button 
												type="button" 
												class="btn btn-medium btn-secondary" 
												@click="onClickCheckDuplicatedNickname"
												:disabled="nickname.length < 2"
												:class="{active: nickname.length >= 2}"
												>{{t('10130')}}</button>
										</div>
										<div class="frms__check" v-show="nickname !== userData.nick_nm">
											<!-- [D] 통과할 경우 success 클래스 추가 -->
											<div class="frms__msg"  v-if="isNicknameDuplication === false && nicknameDuplicationCheck === nickname">
												{{t('10141')}}
											</div>
											<span v-else :class="{success: isNicknameDuplication && nicknameDuplicationCheck === nickname}">
												{{t('10323')}}
											</span>
										</div>
									</div>
								</div>
								<div class="frms__item" v-if="userData.sns_join_flag.length === 0">
									<h3 class="frms__title">{{t('10235')}}</h3>
									<div class="frms__wrap" :class="{success: isInEnglish && isInNumber && isInLength}">
										<input :type="showPassword ? 'text' : 'password'" class="ipt-frm" v-model="password" maxlength="20" @keyup="checkPasswordFormat" :placeholder="t('10772')"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-authtel" v-show="password.length > 0" @click="reset">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
											<button v-if="password.length > 0" type="button" class="btn-frmview" @click="showPassword = !showPassword">
												<i class="icon icon-view" :class="{'disabled': !showPassword}"></i>
											</button>
										</div>
									</div>
									<div class="frms__check">
										<span :class="{success: isInEnglish}">
											{{t('10133')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInNumber}">
											{{t('10134')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInLength}">
											{{t('10135')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
									<div class="frms__wrap" :class="{success: isSamePassword}">
										<input :type="showConfirmPassword ? 'text' : 'password'" class="ipt-frm" v-model="confirmPassword"  :placeholder="t('10831')"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-authtel" v-show="confirmPassword.length > 0" @click="confirmPassword = ''">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
											<button v-if="confirmPassword.length > 0" type="button" class="btn-frmview" @click="showConfirmPassword = !showConfirmPassword">
												<i class="icon icon-view" :class="{'disabled': !showConfirmPassword}"></i>
											</button>
										</div>
									</div>
									<div class="frms__check" v-if="isSamePassword || confirmPassword.length === 0 ">
										<!-- [D] 통과할 경우 success 클래스 추가 -->
										<span :class="{success: isSamePassword}">
											{{t('10137')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
									<div class="frms__msg" v-else>
										{{t('10324')}}
									</div>
								</div>
							</div>
							<div class="edit-links">
								<router-link to="/member/withdraw/step1">
									{{t('10325')}}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm" :class="{'btn-disabled': !isEditAble}" @click="onClickEditUserData" :disabled="!isEditAble">{{t('10143')}}</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { checkPhoneNumber } from '@/utils/formatter';
import usePhoneValidate from '@/composables/usePhoneValidate';
import mmb03 from "@/urls/mmb03";
import mmb01 from "@/urls/mmb01";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = usePhoneValidate("01");

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);

		const isEditPhone = ref(false);
		const userPhoneValidateNumber = ref("");

		const newPhoneNumber = ref("");
		const phoneNumber = ref("");
		const isPhoneNumber = ref(false);

		const nickname = ref(userData.value.nick_nm ?? "");
		const nicknameDuplicationCheck = ref(null);
		const isNicknameDuplication = ref(null);

		const password = ref("");
		const showPassword = ref(false);
		const confirmPassword = ref("");
		const showConfirmPassword = ref(false);

		const isInEnglish = ref(false);
		const isInNumber = ref(false);
		const isInLength = ref(false);

		const userPhoneNumber = computed(() => newPhoneNumber.value.length > 0 ? newPhoneNumber.value : userData.value.hp_no);
		const isSamePassword = computed(() => {
			return password.value === confirmPassword.value && confirmPassword.value !== "";
		});

		const isEditAble = computed(() => {
			return newPhoneNumber.value.length > 0 || nickname.value !== userData.value.nick_nm || password.value.length > 0;
		});

		const checkNumber = () => {
			const [number, isValid] = checkPhoneNumber(phoneNumber.value);
			phoneNumber.value = number;
			isPhoneNumber.value = isValid;
		}

		const onClickEditPhone = () => {
			isEditPhone.value = true;
		}

		const onClickCheckDuplicatedNickname = async () => {
			const result = await mmb01.postMmb0101({proc_cd: "02", data: nickname.value});	

			nicknameDuplicationCheck.value = nickname.value;
			if(result.data.Res_cd === "0000") {
				isNicknameDuplication.value = true;
			}
			else {
				isNicknameDuplication.value = false;
			}
		}

		const goBack = () => {
			router.go(-1);
		};

		const checkPasswordFormat = () => {
			if(password.value.match(/^(?=.*[a-zA-Z])/)){
				isInEnglish.value = true;
			}else {
				isInEnglish.value = false;
			}
			if(password.value.match(/^(?=.*[0-9])/)) {
				isInNumber.value = true;
			}else {
				isInNumber.value = false;
			}
			if(password.value.length >= 8 && password.value.length <= 20) {
				isInLength.value = true;
			}else {
				isInLength.value = false;
			}
		}

		const reset = () => {
			isInEnglish.value = false;
			isInNumber.value = false;
			isInLength.value = false;
			password.value = "";
		}

		const checkValidateNumber = () => {
			if(serverPhoneValidateNumber.value !== userPhoneValidateNumber.value.toString()){
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "연락처 인증번호가 일치하지 않습니다."});
				openErrorAlert.value = true;
				//alert("연락처 인증번호가 일치하지 않습니다.");
			}
			else {
				isEditPhone.value = false;
				newPhoneNumber.value = phoneNumber.value;
			}
		}

		const onClickEditUserData = async () => {
			let newHpNo = userData.value.hp_no;
			let newPasswd = undefined;
			let newNickNm = userData.value.nick_nm;

			// 연락처 변경
			if(newPhoneNumber.value.length > 0){
				if(serverPhoneValidateNumber.value !== userPhoneValidateNumber.value.toString()){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "연락처 인증번호가 일치하지 않습니다."});
					openErrorAlert.value = true;
					//alert("연락처 인증번호가 일치하지 않습니다.");
					return;
				}

				newHpNo = newPhoneNumber.value;
			}

			if(nickname.value !== userData.value.nick_nm){
				if(nicknameDuplicationCheck.value !== nickname.value){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "닉네임 중복확인을 해주세요."});
					openErrorAlert.value = true;
					//alert("닉네임 중복확인을 해주세요.");
					return;
				}
				if(!isNicknameDuplication.value){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "이미 사용중인 닉네임입니다."});
					openErrorAlert.value = true;
					//alert("이미 사용중인 닉네임입니다.");
					return;
				}

				newNickNm = nickname.value;
			}

			if(password.value.length > 0) {
				if(!isSamePassword.value){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "비밀번호가 일치하지 않습니다."});
					openErrorAlert.value = true;
					//alert("비밀번호가 일치하지 않습니다.");
					return;
				}
				if(!(isInEnglish.value && isInNumber.value && isInLength.value)){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "비밀번호는 영문, 숫자를 포함하여 8~20자리로 입력해주세요."});
					openErrorAlert.value = true;
					//alert("비밀번호는 영문, 숫자를 포함하여 8~20자리로 입력해주세요.");
					return;
				}

				newPasswd = password.value;
			}


			const data = await mmb03.getMmb0304({
				mem_id: userData.value.mem_id,
				hp_no: newHpNo,
				passwd: newPasswd,
				nick_nm: newNickNm,
			});
			//console.log(data);
			if(data.data.Res_cd === "0000") {

				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					hp_no: newHpNo ?? userData.value.hp_no,
					nick_nm: newNickNm ?? userData.value.nick_nm,
				}});
				router.go(-1);
			}
			else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message:data.data.Res_msg});
				openErrorAlert.value = true;
				//alert(data.data.Res_msg);
			}
		};

		onMounted(() => {
			if(nickname.value.length <= 0){
				nickname.value = userData.value.nick_nm;
			}
		});
		const { t }= useI18n() //번역필수 모듈

		return {
			goBack,
			isLogin,
			phoneNumber,
			checkNumber,
			userData,
			isEditPhone,
			onClickEditPhone,
			onClickSendPhoneNumber,
			phoneValidateTime,
			onClickEditUserData,
			userPhoneValidateNumber,
			nickname,
			isNicknameDuplication,
			onClickCheckDuplicatedNickname,
			nicknameDuplicationCheck,
			serverPhoneValidateNumber,
			userPhoneNumber,
			isInEnglish,
			isInNumber,
			isInLength,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			password,
			confirmPassword,
			checkPasswordFormat,
			isSamePassword,
			showPassword,
			showConfirmPassword,
			checkPhoneNumber,
			isEditAble,
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(phoneNumber.value);
			},
			reset,
			checkValidateNumber,
			t,
			i18n
		}
	},
}
</script>
