export function checkPhoneNumber(phoneNumber) {
  const number = phoneNumber.replace(/[^0-9]/g, "");
  let phone = "";
  let isPhoneNumber = false;

  if(number.length < 4) { 
    phone = number; 
  }
  else if(number.length < 7) { 
    phone += number.substr(0, 3); 
    phone += "-"; phone += number.substr(3); 
  } 
  else if(number.length < 11) { 
    phone += number.substr(0, 3); 
    phone += "-"; phone += number.substr(3, 3); 
    phone += "-"; phone += number.substr(6); 
  } 
  else { 
    phone += number.substr(0, 3); 
    phone += "-"; 
    phone += number.substr(3, 4); 
    phone += "-"; 
    phone += number.substr(7); 
  }

  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  if(regPhone.test(phone)) {
    isPhoneNumber = true;
  }

  return [phone, isPhoneNumber];
}
