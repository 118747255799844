import { ref, onUnmounted } from "vue";
import mmb00 from "@/urls/mmb00";

export default function usePhoneValidate(proc_cd, mem_id = undefined) {
  const phoneValidateTime = ref(180);
  const phoneValidateTimeInterval = ref(null);
  const serverPhoneValidateNumber = ref("");

  const phoneValidateTimer = () => {
    phoneValidateTime.value--;

    if (phoneValidateTime.value <= 0) {
      clearInterval(phoneValidateTimeInterval.value);
      phoneValidateTime.value = 0;
    }
  };

  onUnmounted(() => {
    clearInterval(phoneValidateTimeInterval.value);
  });

  const onClickSendPhoneNumber = async (phoneNumber) => {
    if (!phoneNumber) {
      return;
    }

    const data = await mmb00.getMmb00({
      proc_cd: proc_cd,
      mem_id: mem_id,
      hp_no: phoneNumber,
    });

    if (data.data.Res_cd === "0000") {
      serverPhoneValidateNumber.value = data.data.Res_data[0].auth_no;
      phoneValidateTime.value = 60 * 3;
      if (phoneValidateTimeInterval.value) {
        clearInterval(phoneValidateTimeInterval.value);
      }
      phoneValidateTimeInterval.value = setInterval(phoneValidateTimer, 1000);
    } else {
      alert(data.data.Res_msg);
      throw new Error(data.data.Res_msg);
    }
  };

  return {
    phoneValidateTime,
    onClickSendPhoneNumber,
    serverPhoneValidateNumber,
  };
}
